import { Booking } from '@vakantiesnl/types';

type TravellersType = {
	pax: number;
	adult: number;
	child: number;
	baby: number;
};

export const mapTravellers = (input: Booking.PersonItems): TravellersType => {
	const travellers = {
		pax: 0,
		adult: 0,
		child: 0,
		baby: 0,
	};

	input.map((t) => {
		travellers.pax++;
		t.ageType == 'ADULT' ? travellers.adult++ : null;
		t.ageType == 'CHILD' ? travellers.child++ : null;
		t.ageType == 'BABY' ? travellers.baby++ : null;
	});

	return travellers;
};
